<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Qrcode -->
          <b-col cols="12" xl="4" lg="2" md="4"></b-col>
          <b-col
            cols="12"
            xl="4"
            lg="8"
            md="4"
            class="align-items-center justify-content-start mb-1 mb-md-0">         
            <!-- <b-card-text class="mt-3" style="font-weight: bold;">Qrcode decode</b-card-text>
            
            <p class="error" v-if="noFrontCamera">
              You don't seem to have a front camera on your device
            </p>

            <p class="error" v-if="noRearCamera">
              You don't seem to have a rear camera on your device
            </p> -->

            <!-- <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
              <button @click="switchCamera">
                <img src="camera-switch.svg" alt="switch camera">
              </button>
            </qrcode-stream> -->

            <StreamBarcodeReader
                @decode="onDecode"
                @loaded="onLoaded"
            ></StreamBarcodeReader>

          </b-col>
          <b-col cols="12" xl="4" lg="2" md="4"></b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTable,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import { StreamBarcodeReader } from "vue-barcode-reader";


export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    StreamBarcodeReader
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loc: [],
      latitude: '',
      longitude: '',
      base64Image: '',
      urlImage: '',
      camera: 'rear',
      noRearCamera: false,
      noFrontCamera: false,
    }
  },
  computed: {

  },
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {

    onDecode(text) {
      // alert(text)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
            title: `Information`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: text,
        },
      })
    },

    onLoaded() {
      console.log(`Ready to start scanning barcodes`)
    },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = error.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true
        }

        console.error(error)
      }
    },

    switchCamera () {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
      }
    },

    takePicturebase64() {
      const image = Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        width: 1024,
        preserveAspectRatio: true,
        resultType: CameraResultType.Base64
      })
      .then(res => {
        this.base64Image = 'data:image/jpeg;base64,'+res.base64String
        console.log(res.base64String)
      })
    },

  },
}
</script>
